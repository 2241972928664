/* Default styles for BlogPage */
.BlogPage {
    width: 800px; /* Set the width */
    margin: 80px auto; /* Center the element horizontally with top margin of 80px */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Posts {
    display: block;
    list-style-type: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}

.BlogPage a {
    text-decoration: none;
    color: #205caa;
    font-size: 40px;
}

.Date {
    display: block;
}

.Posts li {
    margin-bottom: 30px;
}

.BlogPage a:hover {
    text-decoration: underline; /* Underline the text on hover */
    cursor: pointer; /* Change cursor to pointer on hover */
}
@media screen and (max-width: 600px) {
    .BlogPage {
        width: 100%; /* Set width to 100% for smaller screens */
        /* padding: 20px; Add padding for better readability */
    }
    
}
