.DemosPage {
    width: 100%; /* Default to full width */
    max-width: 800px; /* Max width of 800px */
    margin: 100px auto; /* Center the element horizontally with a top margin of 100px */
    display: flex;
    flex-direction: column; /* Arrange children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    padding: 20px; /* Add padding */
    text-align: center; /* Center align text */
}

.demo-item {
    margin-bottom: 20px; /* Add margin between items */
}

/* Media query for screens larger than 800px */
@media (min-width: 801px) {
    .DemosPage {
        width: 800px; /* Set the width */
    }
}

/* Media query for smaller screens */



