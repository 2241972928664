.AboutMePage {
    width: 800px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; Align content and links to top and bottom */
    align-items: center;
    padding: 20px;
    /* background-color: #f5f5f5; */
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    text-align: center;
    margin: 80px auto;
    min-height: 74vh; /* Set minimum height to full viewport height */
    /* position: relative; Position relative for absolute positioning of links */
}

.DescriptionMe {
    width: 80%; /* Adjust the width as needed */
    margin: 0 auto; /* Center the element horizontally */
    text-align: left; /* Align text to the left within the container */
    margin-bottom: 20px; /* Add some bottom margin to separate from links */
}

.Links {
    position: fixed; /* Set position to fixed */
    bottom: 10px; /* Stick to the bottom */
    width: 100%; /* Occupy full width of the container */
    text-align: center; /* Center-align the links section */
    font-size: medium;
}
.Links h3{
    margin-bottom: 0;
}
.Links a {
    display: inline-block; /* Display links inline */
    margin-right: 10px; /* Add spacing between links */
    /* color: #007bff; Change link color if needed */
    text-decoration: none; /* Remove underline */
    font-size:large;
    color: #000;
}

.Links a:last-child {
    margin-right: 0; /* Remove margin from the last link to avoid extra spacing */
}

.Links a:hover {
    text-decoration: underline; /* Underline on hover */
}
.FaGithub{
    color: #000;
}
.FaLinkedin{
    color: #000;
}
@media screen and (max-width: 600px) {
    .AboutMePage {
        width: 100%; /* Set width to 100% for smaller screens */
        padding: 20px; /* Add padding for better readability */
    }
    .DescriptionMe {
        /* width: 100%; Set width to 100% for smaller screens */
    }
    .Links {
        position: static; /* Set position to static for smaller screens */
        margin-top: 20px; /* Add top margin for better spacing */
    }
    
}
.draggable-ball {
    width: 90px;
    height: 90px;
    background-color: black;
    border-radius: 50%;
    cursor: grab;
    position: absolute;
  }
  
  .DescriptionMe span {
    display: inline-block;
  }
  
  .inverted {
    color: white; /* Invert text color */
  }